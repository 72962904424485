import React from "react";
import PropTypes from "prop-types";

import WelcomeBanners from '../WelcomeBanners';

const WelcomeBanner = props => {
  const { bannerTitle, bannerSubTitle, bannerImage } = props;
  return <WelcomeBanners
    bannerTitle={bannerTitle}
    bannerSubTitle={bannerSubTitle}
    bannerImage={bannerImage} />
}

WelcomeBanner.propTypes = {
  bannerTitle: PropTypes.string,
  bannerSubTitle: PropTypes.string,
  bannerImage: PropTypes.string,
}

export default WelcomeBanner
