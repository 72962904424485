import React, { createContext, useState } from "react";

export const CourseContext = createContext();

const CourseProvider = (props) => {
    const [activeTabValue, setactiveTabValue] = useState('');

    return <CourseContext.Provider
        value={{
            activeTabValue,
            setactiveTabValue
        }}
    >
        {props.children}
    </CourseContext.Provider>
}

export default CourseProvider;