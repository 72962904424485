import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const TabLinks = ({ urlLink, activeTab, category }) => {
    const setActiveClass = (link) => {
        if (urlLink) {
            const url = urlLink.hrefLink.split('/')
            if (`/${url[url.length - 1]}` === link) {
                return true
            } else if (!urlLink.hrefLink.includes('#')) {
                if (link === '/#pills-all')
                    return true
            }
            return false
        }
    }

    const toUrl = slug => {
        if (urlLink) {
            if (urlLink.pathLink === '/courses' || urlLink.pathLink === '/courses/') {
                return `courses/#${slug}`;
            } else if (urlLink.pathLink === '/student' || urlLink.pathLink === '/student/') {
                return `student/#${slug}`;
            } else if (urlLink.pathLink === '/') {
                return `/#${slug}`;
            }
            return false;
        }
        return false;
    }
    const setActiveTab = (slug, id) => {
        activeTab(slug === 'pills-all' ? null : id)
    }
    return (
        <div className="tabsRow">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {category.map(({ node }) => (
                    <li className="nav-item"
                        key={node.id}
                        role="button"
                        onClick={() => setActiveTab(node.slug, node.id)}
                        onKeyPress={() => void 0}
                        tabIndex={-42}
                    >
                        <Link
                            to={toUrl(node.slug)}
                            className={setActiveClass(`/#${node.slug}`) ? "nav-link active" : "nav-link"}
                            id="pills-all-tab"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-all"
                            aria-selected="true"
                        >
                            {node.category}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

TabLinks.propTypes = {
    urlLink: PropTypes.object,
    activeTab: PropTypes.func,
    category: PropTypes.array,
}

export default TabLinks;
