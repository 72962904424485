import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import TabLinks from '../TabLinks';
import { TAB_LINKS } from '../../constant/Link';
import { CourseContext } from '../../providers/CourseProvider';
import SingleCourses from './SingleCourses';;

const Courses = ({ courses, urlLink, category }) => {
    const { setactiveTabValue } = useContext(CourseContext);

    const setActiveClass = (link, id) => {
        if (urlLink) {
            const url = urlLink.hrefLink.split('/')
            if (`/${url[url.length - 1]}` === link) {
                return true;
            } else if (!urlLink.hrefLink.includes('#')) {
                if (id === 'pills-all')
                    return true;
            }
            return false;
        }
    }
    const activeTab = tab => {
        setactiveTabValue(tab);
    }
    return (
        <section>
            <div className="Courses">
                <div className="container">
                    <h2 className="sec-title">Our Top Programmes</h2>
                    <TabLinks urlLink={urlLink} activeTab={activeTab} category={category} />
                    <div className="tab-content" id="pills-tabContent">
                        {TAB_LINKS.map(linkdata => (
                            <div
                                key={linkdata.id}
                                className={setActiveClass(linkdata.url, linkdata.id) ? "tab-pane fade show active" : "tab-pane fade"}
                                id={linkdata.id}
                                role="tabpanel"
                                aria-labelledby="pills-all-tab"
                            >
                                <SingleCourses courses={courses} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

Courses.propTypes = {
    courses: PropTypes.array,
}

export default Courses;
