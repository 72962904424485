import { graphql, useStaticQuery } from "gatsby"

const CourseQuery = () => useStaticQuery(graphql`
query{
courses:allContentfulBrainyFiedCourses{
    edges{
      node{
        id
        name
        opening
        slug
        primaryColor
        image{
          fluid{
            ...GatsbyContentfulFluid_withWebp
          }
        }
        salarydetails{
          name
          salary
        }
        category {
          id
          slug
          category
        }
      }
    }
  }
  category:allContentfulBrainyFiedCoursesCategory {
    edges {
      node {
        id
        category
        slug
      }
    }
  }
}`
)

export default CourseQuery;