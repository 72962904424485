import { graphql, useStaticQuery } from "gatsby"

const StudentQuery = () => useStaticQuery(graphql`
query{
    student:jsonDataJson{
      bannerTitle:studentBannerTitle
      bannerSubTitle:studentBannerSubTitle
      bannerImage:studentBannerImage
      description:studentDescription
      secTitle:studentSecTitle
      secDescription:secDescription
      features:studentFeatures{
        id
        featuresImg
        featuresTitle
        featuresDescription
      }
    }
  }`)

export default StudentQuery;