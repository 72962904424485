import { graphql, useStaticQuery } from "gatsby"

const HomeQuery = () => useStaticQuery(graphql`
query {
  site {
    siteMetadata {
      siteData {
        home {
          title1
          title2
          description
          btnIndividual
          btnCorporate
          whyUs{
            title
            whyusImage{
              img
              text
              alt
            }
          }
          explore{
            title
            description1
            description2
            exploreImage{
              id
              image
              alt
              text
              link
            }
          }
          testimonials{
            title
            description1
            description2
            users{
              url
              name
              designation
              description
            }
          }
          blogs{
            title
            description1
            description2
          }
          partners {
            title
            subTitle
            description
            images {
              img
              alt
            }
          }
          hiringPartner{
            title
            subTitle
            description
            images{
              img
              alt
            }
          }
        }
      }
    }
  }
  courses:allContentfulBrainyFiedCourses(limit:6){
    edges{
      node{
        id
        name
        opening
        slug
        primaryColor
        image{
          fluid{
            ...GatsbyContentfulFluid_withWebp
          }
        }
        salarydetails{
          name
          salary
        }
        category {
          id
          slug
          category
        } 
      }
    }
  }
  category:allContentfulBrainyFiedCoursesCategory {
    edges {
      node {
        id
        category
        slug
      }
    }
  }
}`);

export default HomeQuery;