import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WelcomeBanner from "../components/Student/WelcomeBanner"
import About from "../components/Student/About"
import HowItWorks from "../components/Student/HowItWorks"
import Features from "../components/Student/Features"
import Courses from "../components/Courses/Courses"
import { StudentQuery, CoursesQuery } from '../query';
import CourseProvider from "../providers/CourseProvider";

const Student = () => {
  const { student } = StudentQuery();
  const { courses, category } = CoursesQuery();

  const getUrl = () => {
    if (typeof window !== 'undefined')
      return {
        hrefLink: window.location.href,
        pathLink: window.location.pathname,
      }
  }

  return (
    <Layout>
      <SEO title="Student" />
      <WelcomeBanner
        bannerTitle={student.bannerTitle}
        bannerSubTitle={student.bannerSubTitle}
        bannerImage={student.bannerImage}
      />
      <About description={student.description} />
      <CourseProvider>
        <Courses courses={courses.edges} urlLink={getUrl()} category={category.edges} />
      </CourseProvider>
      <HowItWorks
        secTitle={student.secTitle}
        secDescription={student.secDescription}
      />
      <Features features={student.features} />
    </Layout>
  )
}

export default Student
