import React from "react"
import PropTypes from "prop-types"

const Features = ({ features }) => {
  return (
    <div>
      <div className="Features">
        <div className="container">
          <div className="row">
            {features.map((featuresData, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="featureBox">
                  <div className="FeatureImg">
                    <img
                      src={featuresData.featuresImg}
                      alt={featuresData.featuresTitle}
                    />
                  </div>
                  <h4 className="FeatureHead">{featuresData.featuresTitle}</h4>
                  <p>{featuresData.featuresDescription}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Features.propTypes = {
  features: PropTypes.array,
}

export default Features
