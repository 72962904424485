import React from "react"
import PropTypes from "prop-types"

const About = ({ description }) => {
  return (
    <div>
      <div className="About">
        <div className="container">
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}

About.propTypes = {
  description: PropTypes.string
}

export default About
