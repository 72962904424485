import React from "react"
import PropTypes from "prop-types"

import img01 from "../../images/w01.svg"
import img02 from "../../images/w02.svg"
import img03 from "../../images/w03.svg"

const HowItWorks = ({ secTitle, secDescription }) => {
  return (
    <div>
      <div className="HowItWorks">
        <div className="container">
          <div className="HeadingSec">
            <h2 className="SecTitle">{secTitle}</h2>
            <p>{secDescription}</p>
          </div>
          <div>
            <div className="flow_row">
              <div className="row align-items-center justify-content-between timeline_content mt-5">
                <div className="col-2 col-md-2 col-sm-12 text-center bottom">
                  <div className="img">
                    <img src={img01} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-12">
                  <h5>Learn</h5>
                  <p>
                    Ei quo solum dolores elaboraret, no phaedrum rationibus vix.
                    Suscipit conclusionemque sed eu, eu qui elit quaeque
                    patrioque. Accusam deseruisse ei mea, his in cetero
                    voluptua, populo{" "}
                  </p>
                </div>
              </div>

              <div className="row timeline">
                <div className="col-2 col-md-2">
                  <div className="corner top-right"></div>
                </div>
                <div className="col-8 col-md-8 ">
                  <hr />
                </div>
                <div className="col-2 col-md-2">
                  <div className="corner left-bottom"></div>
                </div>
              </div>

              <div className="row align-items-center justify-content-between timeline_content">
                <div className="col-4 col-md-4 col-sm-12 text-left">
                  <h5>Practice</h5>
                  <p>
                    Ei quo solum dolores elaboraret, no phaedrum rationibus vix.
                    Suscipit conclusionemque sed eu, eu qui elit quaeque
                    patrioque. Accusam deseruisse ei mea, his in cetero
                    voluptua, populo{" "}
                  </p>
                </div>
                <div className="col-2 col-md-2 col-sm-12 text-center full">
                  <div className="img">
                    <img src={img02} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>

              <div className="row timeline">
                <div className="col-2 col-md-2">
                  <div className="corner right-bottom"></div>
                </div>
                <div className="col-8 col-md-8">
                  <hr />
                </div>
                <div className="col-2 col-md-2">
                  <div className="corner top-left"></div>
                </div>
              </div>

              <div className="row align-items-center justify-content-between timeline_content">
                <div className="col-2 col-md-2 col-sm-12 text-center top">
                  <div className="img">
                    <img src={img03} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-12">
                  <h5>Work</h5>
                  <p>
                    Ei quo solum dolores elaboraret, no phaedrum rationibus vix.
                    Suscipit conclusionemque sed eu, eu qui elit quaeque
                    patrioque. Accusam deseruisse ei mea, his in cetero
                    voluptua, populo{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="flow_row">
              <div className="row align-items-center justify-content-between timeline_content mt-5">
                <div className="col-2 col-md-2 col-sm-12 text-center bottom">
                  <div className="img">
                    <img src={img01} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-12">
                  <h5>Learn</h5>
                  <p>
                    Ei quo solum dolores elaboraret, no phaedrum rationibus vix.
                    Suscipit conclusionemque sed eu, eu qui elit quaeque
                    patrioque. Accusam deseruisse ei mea, his in cetero
                    voluptua, populo{" "}
                  </p>
                </div>
              </div>

              <div className="row timeline">
                <div className="col-2 col-md-2">
                  <div className="corner top-right"></div>
                </div>
                <div className="col-8 col-md-8 ">
                  <hr />
                </div>
                <div className="col-2 col-md-2">
                  <div className="corner left-bottom"></div>
                </div>
              </div>

              <div className="row align-items-center justify-content-between timeline_content">
                <div className="col-4 col-md-4 col-sm-12 text-left">
                  <h5>Practice</h5>
                  <p>
                    Ei quo solum dolores elaboraret, no phaedrum rationibus vix.
                    Suscipit conclusionemque sed eu, eu qui elit quaeque
                    patrioque. Accusam deseruisse ei mea, his in cetero
                    voluptua, populo{" "}
                  </p>
                </div>
                <div className="col-2 col-md-2 col-sm-12 text-center full">
                  <div className="img">
                    <img src={img02} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>

              <div className="row timeline">
                <div className="col-2 col-md-2">
                  <div className="corner right-bottom"></div>
                </div>
                <div className="col-8 col-md-8">
                  <hr />
                </div>
                <div className="col-2 col-md-2">
                  <div className="corner top-left"></div>
                </div>
              </div>

              <div className="row align-items-center justify-content-between timeline_content">
                <div className="col-2 col-md-2 col-sm-12 text-center top">
                  <div className="img">
                    <img src={img03} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-12">
                  <h5>Work</h5>
                  <p>
                    Ei quo solum dolores elaboraret, no phaedrum rationibus vix.
                    Suscipit conclusionemque sed eu, eu qui elit quaeque
                    patrioque. Accusam deseruisse ei mea, his in cetero
                    voluptua, populo{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HowItWorks.propTypes = {
  secTitle: PropTypes.string,
  secDescription: PropTypes.string,
}

export default HowItWorks
