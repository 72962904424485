import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { Link } from "gatsby"

import { CourseContext } from "../../providers/CourseProvider"

const SingleCourses = ({ courses }) => {
  const [filterValue, setFilterValue] = useState(null)
  const { activeTabValue } = useContext(CourseContext)

  useEffect(() => {
    const data = []
    if (activeTabValue) {
      const filterData = courses.filter(({ node }) =>
        node.category.find(categoryData => categoryData.id === activeTabValue)
      )
      filterData.map(fillData => {
        data.push(fillData.node)
        return true
      })
      setFilterValue(data)
    } else {
      courses.map(courseValue => {
        data.push(courseValue.node)
        return true
      })
      setFilterValue(data)
    }
  }, [activeTabValue, courses])

  return (
    <div className="row">
      {filterValue &&
        filterValue.map(
          ({
            id,
            name,
            slug,
            opening,
            primaryColor,
            image: { fluid },
            salarydetails,
          }) => (
            <div
              className="col-sm-12 col-md-6 col-lg-6 col-xl-4 checkKey"
              key={id}
            >
              <div className="courseCard">
                <div
                  className="courseIC"
                  style={{ backgroundColor: primaryColor }}
                >
                  <Image fluid={fluid} className="img-fluid" />
                </div>
                <h4 className="courseTitle">{name}</h4>
                <div className="CurrentOpening">
                  <h6>Current Opening:</h6>
                  <div className="CurrentOpeningProgress">
                    <span className="CurrentOpeningProgressActive"></span>
                  </div>
                  <div className="OpeningDetails">
                    <span className="ValueStart">0</span>
                    <span className="ValueEnd">{opening}</span>
                  </div>
                  {salarydetails.map((detailsData, index) => (
                    <div className="OpeningDetails" key={index}>
                      <span className="ValueStart">{detailsData.name}</span>
                      <span className="ValueEnd">{detailsData.salary}</span>
                    </div>
                  ))}
                </div>
                <div>
                  <Link to={`/courses/${slug}`}>View Course</Link>
                </div>
              </div>
            </div>
          )
        )}
    </div>
  )
}

SingleCourses.propTypes = {
  courses: PropTypes.array,
}

export default SingleCourses
